import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import ScheduleView from "./ScheduleView.js";
import NewModal from "./NewModal";
import Info from "./Info.js";
import queryString from "query-string";
import dayjs from "dayjs";
import { useUserSettings } from "../../auth/useUserSettings";
import fetchData from "./scripts/fetchData.js";

export default function PublishReport() {
  const [countryList, setCountryList] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCentre, setSelectedCentre] = useState();
  const [centreOptions, setCentreOptions] = useState([]);
  const [scheduleLog, setScheduleLog] = useState([]);
  const [rowLimit, setRowLimit] = useState(25);
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const [classId, setClassId] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [userSettings] = useUserSettings();
  const searchRef = useRef();

  const getCountries = async () => {
    try {
      const countryIdList = userSettings.country_access;
      const url = `${
        process.env.REACT_APP_ROOT_URL
      }/schedule/country-list?${queryString.stringify(countryIdList, {
        arrayFormat: "bracket",
      })}`;
      const response = await fetchData(url, "GET");
      setCountryList(response);
      const countries = response.map((option) => ({
        value: option.country_id,
        label: option.country_name,
      }));
      if (userSettings.country_access.length > 1) {
        countries.unshift({ value: 0, label: "All countries" });
      }
      setCountryOptions(countries);
      const currentCountry = JSON.parse(localStorage.getItem("centre"));
      const currentCountryData = response.find(
        (country) => country.country_id === currentCountry.country_id
      );
      setSelectedCountry(
        userSettings.roles.includes(28)
          ? countries[0]
          : {
              value: currentCountryData.country_id,
              label: currentCountryData.country_name,
            }
      );
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  const loadCentres = () => {
    if (!countryList) return;

    const getCentres = (countries) => {
      return countries.reduce((centres, country) => {
        return centres.concat(
          country.centres.map((centre) => ({
            value: centre.centre_id,
            label: `${centre.centre_name} (${centre.centre_code})`,
          }))
        );
      }, []);
    };

    let centres;
    if (selectedCountry?.value === 0) {
      centres = getCentres(countryList);
    } else {
      const country = countryList.find(
        (country) => country.country_id === selectedCountry.value
      );
      centres = getCentres([country]);
    }

    selectedCountry?.value === 0 &&
      centres.sort((a, b) => a.label.localeCompare(b.label));
    centres.unshift({ value: 0, label: "All centres" });
    setCentreOptions(centres);
    setSelectedCentre(centres[0]);
  };

  const getScheduleLogs = async () => {
    setSearchMode(false);
    setClassId("");
    const centreIdArray = centreOptions.map((option) => option.value);
    const centreIdList =
      selectedCentre?.value === 0
        ? centreIdArray.slice(1)
        : [selectedCentre?.value];
    const url = `${
      process.env.REACT_APP_ROOT_URL
    }/schedule/schedule-logs/${rowLimit}/logs?${queryString.stringify(
      centreIdList,
      {
        arrayFormat: "bracket",
      }
    )}`;
    try {
      const response = await fetchData(url, "GET");
      if (response) {
        setScheduleLog(response);
      }
    } catch (error) {
      console.error("Failed to fetch schedule logs:", error);
    }
  };

  function showMoreRows() {
    setRowLimit(rowLimit + 25);
  }

  const showInfoModal = (content) => {
    setNewModalContent([<Info info={content} />, true]);
    setShowNewModal(true);
  };

  useEffect(() => {
    loadCentres();
  }, [selectedCountry]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getScheduleLogs();
  }, [selectedCentre, rowLimit]);

  const findCentreById = (centreId) => {
    for (const country of countryList) {
      const centre = country.centres?.find(
        (centre) => centre.centre_id === centreId
      );
      if (centre) {
        return centre;
      }
    }
    return null;
  };

  const getResponseList = async (jobId) => {
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/response-logs/${jobId}`;
      const response = await fetchData(url, "GET");
      return response;
    } catch (error) {
      console.error("Failed to fetch response list:", error);
      return [];
    }
  };

  const viewSchedule = async (scheduleId, centreId, jobId) => {
    const centreData = findCentreById(centreId);
    const responseList = await getResponseList(jobId);
    setNewModalContent([
      <ScheduleView
        centre={centreData}
        scheduleId={scheduleId}
        isLoaded={false}
        closeModal={closeModal}
        loadSchedule={null}
        responseList={responseList}
      />,
      true,
    ]);
    setShowNewModal(true);
  };

  const closeModal = () => {
    setShowNewModal(false);
  };

  const searchScheduleByClassId = async () => {
    if (!classId) {
      searchRef.current.focus();
      return;
    } else if (!validateClassId(classId)) {
      showInfoModal("Invalid class ID.");
      return;
    }
    const centreIdArray = centreOptions.map((option) => option.value);
    const centreIdList =
      selectedCentre?.value === 0
        ? centreIdArray.slice(1)
        : [selectedCentre?.value];
    const url = `${
      process.env.REACT_APP_ROOT_URL
    }/schedule/search-schedule/${classId}/centres?${queryString.stringify(
      centreIdList,
      {
        arrayFormat: "bracket",
      }
    )}`;
    try {
      const response = await fetchData(url, "GET");
      if (response) {
        setScheduleLog([response]);
        setSearchMode(true);
      } else {
        showInfoModal("Sorry, class not found.");
      }
    } catch (error) {
      console.error("Failed to fetch schedule logs:", error);
    }
  };

  const validateClassId = (classId) => {
    // should have 3 letters followed by 6 0r 7 numbers followed by two letters, total length 11 or 12
    const regex = /^[A-Z]{3}[0-9]{6,7}[A-Z]{2}$/;
    return regex.test(classId);
  };

  const clearSearch = () => {
    getScheduleLogs();
    // setSearchMode(false);
    // setClassId("");
  };

  return (
    <div>
      <h2>Schedule publish log</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div">
          <div className="centre-selector-div">
            <Select
              className="schedule-form-country-selector"
              name="country"
              value={selectedCountry}
              placeholder="Country"
              cacheOptions
              isSearchable
              options={countryOptions}
              onChange={(data) => setSelectedCountry(data)}
            />
            <Select
              className="schedule-form-centre-selector"
              name="centre"
              value={selectedCentre}
              placeholder="Centre"
              cacheOptions
              isSearchable
              options={centreOptions}
              onChange={(data) => setSelectedCentre(data)}
            />
          </div>
        </div>
        <div className="schedule-container-right-div">
          <input
            type="text"
            ref={searchRef}
            placeholder="Seach by class ID"
            value={classId}
            onChange={(e) => setClassId(e.target.value)}
          />
          <button
            className="schedule-form-button"
            onClick={
              searchMode === false ? searchScheduleByClassId : clearSearch
            }
          >
            {searchMode === false ? "Search" : "Clear"}{" "}
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Country</th>
            <th>Centre</th>
            {userSettings.roles.includes(28) && <th>Schedule ID</th>}
            <th>Schedule name</th>
            <th>Classes</th>
            <th>Created by</th>
            <th>Job ID</th>
            <th>Started</th>
            <th>Completed</th>
            <th>Dur. (s)</th>
            <th>
              <i className="fa-regular fa-magnifying-glass"></i>
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {scheduleLog?.map((log, index) => (
            <tr key={log._id}>
              <td>{log.country_name}</td>
              <td className="centered-column">
                <span title={log.centre_name}>{log.centre_code}</span>
              </td>
              {userSettings.roles.includes(28) && (
                <td className="centered-column">{log.schedule_id}</td>
              )}
              <td>{log.schedule_name}</td>
              <td className="numeric-column">{log.classlist_length}</td>
              <td>{log.username}</td>
              <td className="numeric-column">{log.job_id && log.job_id}</td>
              <td className="centered-column">
                {dayjs(log.createdAt).format("DD/MM/YYYY HH:mm:ss")}
              </td>
              <td className="centered-column">
                {dayjs(log.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
              </td>
              <td className="numeric-column">
                {dayjs(log.updatedAt).diff(dayjs(log.createdAt), "second")}
              </td>
              <td className="centered-column">
                <span
                  className="link"
                  onClick={() =>
                    viewSchedule(log.schedule_id, log.centre_id, log.job_id)
                  }
                >
                  <i className="fa-regular fa-magnifying-glass"></i>
                </span>
              </td>
              <td
                className={`centered-column ${
                  log.job_status?.toString() === "true"
                    ? "publish-sucess"
                    : "publish-fail"
                }`}
              >
                {/* {log.job_status?.toString()} */}{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {scheduleLog?.length === rowLimit && (
        <div className="close-button-div">
          <button className="schedule-form-button" onClick={showMoreRows}>
            Show more
          </button>
        </div>
      )}
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
      />
    </div>
  );
}
