export default function DayPickerComponent({
  weekday,
  isEnabled,
  setWeekdayArray,
}) {
  const days = [
    [1, "M"],
    [2, "T"],
    [3, "W"],
    [4, "T"],
    [5, "F"],
    [6, "S"],
    [0, "S"],
  ];

  const selectAllDays = () => {
    const allDays = days.map((day) => day[0]);
    setWeekdayArray(true, allDays, true);
  };

  const showDays = days.map((day) => {
    return (
      <div key={day[0]}>
        <input
          key={day[0]}
          type="checkbox"
          id={"D" + day[0]}
          value={weekday.includes(day[0])}
          checked={weekday.includes(day[0])}
          disabled={!isEnabled}
          className="weekday-selector"
          onChange={(e) => setWeekdayArray(e.target.checked, day[0], true)}
        />
        <label htmlFor={"D" + day[0]}>{day[1]}</label>
      </div>
    );
  });

  return (
    <div className="weekday-selector">
      {showDays}
      {(() => {
        const hasSelectedDays = weekday.length > 0;
        return (
      <span
            title={hasSelectedDays ? "Reset dates" : "Select all dates"}
            onClick={() =>
              isEnabled
                ? hasSelectedDays
                ? setWeekdayArray(null, null, false)
                : selectAllDays()
                : null
            }
        className="weekday-selector"
      >
            {hasSelectedDays ? "R" : "A"}
      </span>
        );
      })()}
    </div>
  );
}
