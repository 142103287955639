import React, { useEffect, useState } from "react";
import LoginHeader from "../components/layout/LoginHeader.js";
import Footer from "../components/layout/Footer.js";
import VerifyOtp from "./VerifyOtp.js";
import { useNavigate, useLocation } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { useUserSettings } from "../auth/useUserSettings";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function Login() {
  const location = useLocation();
  const [token, setToken] = useToken();
  const [otpToken, setOtpToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [, setUserSettings] = useUserSettings();
  const [user, setUser] = useState();
  const [showpassword, setShowpassword] = useState(false);
  const [requireOtp, setRequireOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  function handleFormChange(e) {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  }

  /* Check user already has valid token and redirect to schedule page or login */
  async function checkLoginStatus() {
    try {
      if (token) {
        let url = `${process.env.REACT_APP_ROOT_URL}/schedule/verify-token/${token}`;
        const response = await fetchData(url, "GET");
        if (response?.message !== "ok") {
          localStorage.clear();
          setIsLoggedIn(false);
          navigate("/login");
        } else {
          setIsLoggedIn(true);
          navigate(location.state ? location.state : "/schedule");
        }
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error("Error checking login status:", error);
      setIsLoggedIn(false);
    }
  }

  /* If login process login form */
  const handleLogin = async (e) => {
    e.preventDefault();
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/login`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });
      if (response.status === 200) {
        let token = await response.json();
        let tempToken = token;
        console.log(token);
        // document.cookie = `token=${token}`;
        let payload = JSON.parse(atob(token.split(".")[1]));
        const userObj = payload.user;
        setUser(userObj);
        let requireOtp = await checkRequireOtp(userObj.username, tempToken);
        if (!requireOtp) {
          setRequireOtp(true);
          setOtpToken(tempToken);
        } else {
          loginUser(userObj, tempToken);
        }
      } else {
        let responseObj = await response.json();
        setErrorMessage(responseObj.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  /* Check if the user is due an MFA check */
  async function checkRequireOtp(username, tempToken) {
    try {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/last-mfa`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tempToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username }),
      }).then((data) => data.json());
      return response;
    } catch (error) {
      console.error("Error checking OTP requirement:", error);
      return false;
    }
  }

  /* Log the user in checking if they need news and logging the login */
  async function loginUser(userObj, tempToken) {
    try {
      const requireNewsUpdate = await checkUserNewsUpdate(
        userObj.username,
        tempToken
      );
      setToken(tempToken);
      setUserSettings(userObj);
      setIsLoggedIn(true);
      logLogin(userObj.username, requireOtp, "login");
      navigate(location.state ? location.state : "/schedule", {
        state: requireNewsUpdate,
      });
    } catch (error) {
      console.error("Error logging in user:", error);
      setErrorMessage("An error occurred during login. Please try again.");
    }
  }

  /* Check if the user needs to see the news modal */
  const checkUserNewsUpdate = async (username, tempToken) => {
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/news/check-news-update/${username}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      }).then((data) => data.json());
      console.log(response);
      return response.hasUpdates;
    } catch (error) {
      console.error("Error checking user news update:", error);
      return false;
    }
  };

  /* Temp functiong include the user's screen resolution in the login log */
  const getUserScreenResolution = () => {
    return window.screen.width + ":" + window.screen.height;
  };

  /* Log user's login */
  async function logLogin(username, mfa, route) {
    try {
      let screen_resolution = getUserScreenResolution();
      let loginObj = {
        username: username,
        mfa: mfa,
        route: route,
        screen_resolution,
      };
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/log-login`;
      await fetchData(url, "POST", loginObj);
    } catch (error) {
      console.error("Error logging login:", error);
    }
  }

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return !isLoggedIn ? (
    <>
      <LoginHeader />
      <main>
        <div className="login-wrapper">
          <div className="login-form">
            {requireOtp ? (
              <VerifyOtp
                user={user}
                loginUser={loginUser}
                setRequireOtp={setRequireOtp}
                otpToken={otpToken}
              />
            ) : (
              <div>
                <h3> Please log in</h3>
                <form id="myFormloginForm" onSubmit={handleLogin}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      name="username"
                      className="login-input"
                      id="username"
                      required
                      onChange={(e) => handleFormChange(e)}
                      value={loginData.username}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type={showpassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="form-control login-input"
                      onChange={(e) => handleFormChange(e)}
                      value={loginData.password}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-check-label"
                      htmlFor="showpasswordcheck"
                    >
                      <input
                        type="checkbox"
                        checked={showpassword}
                        className="login-show-password-checkbox"
                        name="showpasswordcheck"
                        id="showpasswordcheck"
                        onChange={() => setShowpassword(!showpassword)}
                      />
                      Show password
                    </label>
                  </div>
                  <div className="login-button-div">
                    <button
                      type="submit"
                      id="loginBtn"
                      value="Log in"
                      className="schedule-form-button"
                    >
                      Log in
                    </button>
                  </div>
                  <div className="login-error-div">{errorMessage}</div>
                </form>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  ) : (
    <></>
  );
}
