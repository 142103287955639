import React from "react";
import { Link, useNavigate } from "react-router-dom";
import t2_logo from "../schedule/images/t2_logo.png";
import { useUserSettings } from "../../auth/useUserSettings";

export default function Header({ showNewsModal }) {
  const navigate = useNavigate();
  const [userSettings] = useUserSettings();
  let userRoles = userSettings?.roles;
  let userDefaultCountry = userSettings?.user_settings.default_country;

  function logOff() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <header>
      <div className="header-title-div">
        <img src={t2_logo} alt="T2" className="t2-logo" />
        {/* <h2>T2</h2> */}
      </div>
      {userRoles && (
        <nav>
          <Link to="/schedule" className="header-link">
            Scheduler
          </Link>{" "}
          |
          <Link to="/strands" className="header-link">
            Lesson groups
          </Link>{" "}
          |
          {userRoles?.includes(28) && (
            <>
              <Link to="/teacher-view" className="header-link">
                Teachers
              </Link>{" "}
              |
            </>
          )}
          <Link to="/schedule-archive" className="header-link">
            Archive
          </Link>{" "}
          |
          {userRoles?.includes(28) && (
            <>
              <Link to="/zoom-accounts" className="header-link">
                Zoom admin
              </Link>{" "}
              |
            </>
          )}
          {userDefaultCountry === 10 && (
            <>
              <Link to="/zoom" className="header-link">
                Zoom
              </Link>{" "}
              |
            </>
          )}
          <Link onClick={showNewsModal} className="header-link">
            News
          </Link>{" "}
          |
          <Link to="/publish-report" className="header-link">
            Logs
          </Link>{" "}
          |
          {userRoles?.includes(5) && (
            <>
              <Link to="/admin-accounts" className="header-link">
                Users
              </Link>{" "}
              |
              <Link to="/date-tests" className="header-link">
                Date tests
              </Link>{" "}
              |
            </>
          )}
          <Link to="/" onClick={logOff} className="header-link">
            Log off
          </Link>
        </nav>
      )}
    </header>
  );
}
