import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import ZoomSubAccountUserMeetings from "./ZoomSubAccountUserMeetings";
import fetchData from "../schedule/scripts/fetchData.js";

export default function ZoomSubAccountUser({
  accountId,
  userId,
  setShowNewModal,
}) {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getUserData();
  }, [userId]);

  const getUserData = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/zoom/${accountId}/${userId}`;
    try {
      const response = await fetchData(url, "GET");
      setUserData(response);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <div className="zoom-user-modal">
      <h2>
        {userData.first_name} {userData.last_name}
      </h2>
      <div className="zoom-account-div">
        <div className="zoom-account-left-div">
          <table>
            <tbody>
              <tr>
                <td className="zoom-header-cell">Last name</td>
                <td clsssName="zoom-info-cell">{userData.last_name}</td>
                <td className="zoom-header-cell">User account ID</td>
                <td clsssName="zoom-info-cell">{userData.id}</td>
              </tr>
              <tr>
                <td className="zoom-header-cell">First name</td>
                <td clsssName="zoom-info-cell">{userData.first_name}</td>
                <td className="zoom-header-cell">PMI</td>
                <td clsssName="zoom-info-cell">{userData.pmi}</td>
              </tr>
              <tr>
                <td className="zoom-header-cell">Email</td>
                <td clsssName="zoom-info-cell">{userData.email}</td>

                <td className="zoom-header-cell">Created</td>
                <td clsssName="zoom-info-cell">
                  {dayjs(userData.created_at).format("DD/MM/YYYY HH:mm")}
                </td>
              </tr>
              <tr>
                <td className="zoom-header-cell">Time zone</td>
                <td clsssName="zoom-info-cell">{userData.timezone}</td>
                <td className="zoom-header-cell">Last login</td>
                <td clsssName="zoom-info-cell">
                  {dayjs(userData.last_login_time).format("DD/MM/YYYY HH:mm")}
                </td>
              </tr>
              <tr>
                <td className="zoom-header-cell">Status:</td>
                <td className="zoom-info-cell">{userData.status}</td>
                <td className="zoom-header-cell">License:</td>
                <td className="zoom-info-cell">
                  {userData.type === 1
                    ? "Basic"
                    : userData.type === 2
                    ? "Licensed"
                    : "None"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="zoom-account-right-div">
          <button className="schedule-form-button disabled" disabled>
            Change type
          </button>
          <button className="schedule-form-button" disabled>
            Change status
          </button>
          <button className="schedule-form-button" disabled>
            Delete
          </button>
        </div> */}
      </div>
      {userData.type && (
        <div>
          <ZoomSubAccountUserMeetings
            accountId={accountId}
            userId={userId}
            typeId={userData.type}
          />
        </div>
      )}
      <div className="close-button-div">
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
}
