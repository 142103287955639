import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { useUserSettings } from "../auth/useUserSettings";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function LoginObs() {
  const [, setToken] = useToken();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [, setUserSettings] = useUserSettings();
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const { obstoken } = useParams();

  const getUserScreenResolution = () => {
    return window.screen.width + ":" + window.screen.height;
  };

  const logLogin = async (loginData) => {
    try {
      let screen_resolution = getUserScreenResolution();
      let userObj = {
        username: loginData,
        mfa: false,
        route: "obs",
        screen_resolution,
      };
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/log-login`;
      await fetchData(url, "POST", userObj);
    } catch (error) {
      console.error("Error logging login:", error);
    }
  };

  const checkUserNewsUpdate = async (username) => {
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/news/check-news-update/${username}`;
      const response = await fetchData(url, "GET");
      console.log(response);
      return response.hasUpdates;
    } catch (error) {
      console.error("Error checking user news update:", error);
      return false;
    }
  };

  const loginUser = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs-login`;
    try {
      const new_token = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${obstoken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: obstoken }),
      }).then((data) => data.json());
      if (new_token && new_token !== "undefined") {
        setToken(new_token);
        let payload = JSON.parse(atob(new_token.split(".")[1]));
        setUserSettings(payload.user);
        const requireNewsUpdate = await checkUserNewsUpdate(
          payload.user.username
        );
        logLogin(payload.user.username);
        setIsLoggedIn(true);
        navigate("/schedule", {
          state: requireNewsUpdate,
        });
      } else {
        setErrorMessage("Invalid token received.");
      }
    } catch (error) {
      setErrorMessage("Whoops, something has gone wrong.");
    }
  };

  useEffect(() => {
    loginUser();
  }, []);

  return isLoggedIn ? (
    <>
      {errorMessage && (
        <>
          <h2>Error</h2>
          <p>{errorMessage}</p>
        </>
      )}
    </>
  ) : (
    <>Logging in ...</>
  );
}
